@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

svg {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.shadow {
  /* box-shadow: 10px 20px 15px silver; */
  filter: drop-shadow(6px 6px 3px #757575);
}

textarea {
  padding: 5px;
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-family: "Nanum Myeongjo";
}
.dreieck {
  position: absolute;
  top: -1px;
  left: -5px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid red;
  transform: rotate(-45deg);
}

.infobox-container {
  height: auto;
  width: 220px;
  padding: 0px;
}

.infobox {
  height: auto;
  width: 220px;
  margin: 0px;
}

.logo {
  max-height: 30px;
  height: 75%;
  width: auto;
  max-width: 100%;
  margin: 0;
}

.marker-logo {
  width: 75%;
  height: auto;
  max-height: 60px;
  margin-bottom: 20px;
}

.leadform {
  padding: 0px;
  width: 60vw;
  max-width: 350px;
  box-shadow: 1px 1px 3px grey;
  background: white;
  padding: 5px;
  border-radius: 0 4px 4px 4px;
  border: 1px solid grey;
  font-size: 1.2rem;
}

.buttonbar {
  display: "inline-flex";
  flex-direction: "row";
  width: 100%;
  justify-content: "space-around";
  text-align: center;
}

select {
  background-color: white;
  color: black;
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 3px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-label {
  font-size: 12px;
}

.input-border {
  border: 1px solid lightgrey;
  border-radius: 4px;
  font-family: "Arial";
  font-size: 0.8rem;
}

::placeholder {
  color: grey;
  font-size: 0.8rem;
  font-family: Arial;
  line-height: 100%;
}

.leadform-input {
  display: flex;
  flex-direction: column;
  margin: 5px;
  font-size: 1.2rem;
  color: black;
}

h2 {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.1rem;
  text-align: left;
  padding-right: 90px;
}

body {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.container {
  display: flex;
  position: absolute;
  padding: 0;
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.modal {
  padding: 50px 0 0 0;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0,48,94,1)}
  


.modal-input-container {
  display: flex;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  border: 1px lightgrey solid;
  height: 300px;
  max-height: 50vw;
  width: 500px;
  max-width: 70vw;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 100px;
  left: 100px;
  background: rgba(0,48,94,1)}

.modal-input {
  padding: 2px;
  width: 300px;
  max-width: 60vw;
  height: 30px;
  max-height: 3vh;
  margin: 10px;
  font-size: 1.1rem;
  color: grey;
  border: none;
  border-radius: 2px;
  background: #f5f5f5;
}

.modal-logo {
  position: relative;
  height: 100px;
  max-height: 20vh;
  width: 100px;
  margin: 20px;
}

.marker-position {
  position: absolute;
  top: -30px;
  left: -15px;
}

.controls {
  position: absolute;
  border-radius: 4px;
  height: auto;
  width: 120px;
  top: 12px;
  left: 12px;
  margin: 2px;
  padding: 5px;
  z-index: 199;
}

.switch {
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  padding: 5px;
  margin: 6px;
  background-color: white;
  box-shadow: 1px 1px 1px grey;
  border: 1px solid lightgrey;
}

.button-text {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
}

.headline {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin: 0 0 0 0;
}

header {
  display: flex;
  flex-direction: row;
  position: fixed;
  vertical-align: middle;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  padding: 5px 10px;
  line-height: 30px;
  /*background-color: #009ac3;*/
  background-color: white;
  color: #002849;
  z-index: 950;
  box-shadow: 1px 2px 2px #888888;
}

.filter {
  align-items: left;
  margin-left: 10px;
}

.filter-icon-container {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  margin: 0;
}

.filter-icon {
  height: 24px;
  width: 24px;
  margin: 0;
  margin-top: 5px;
}

.control-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: left;
}

.popup-button {
  display: inline-flex;
  justify-content: space-around;
  border: none;
  width: 40%;
  border-radius: 4px;
  padding: 5px 15px;
  margin: 10px;
  text-decoration: none;
  background: forestgreen;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.button-class {
  display: block;
  padding: 0px;
  width: 100px;
  height: 2rem;
  margin: 20px;
  background: #007bff;
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;
  font-size: 1.2rem;
  text-align: center;
  align-items: center;
  line-height: 2rem;
  border-radius: 2px;
  font-weight: normal;
  z-index: 200;
}

.text {
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
}

.settings-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  animation-duration: 3s;
  align-items: center;
  /* animation-name: slidein; */
}

/* @keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
} */

.marker-circle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #a9a9f5;
  border-style: solid;
  border-radius: 50%;
  border-color: grey;
  border-width: 1px;
  z-index: 80;
  text-align: center;
  line-height: 2.18;
  vertical-align: middle;
  font-weight: bold;
  font-size: 0.8em;
  font-family: Arial;
  box-shadow: 2px 0px 8px 1px grey;
}

.hidden {
  display: none;
}

.marker-triangle {
  position: relative;
  top: 20px;
  left: 6px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 20px solid #a9a9f5;
  z-index: 79;
}

.infocard {
  position: absolute;
  padding: 10px;
  top: -100px;
  left: -50px;
  border-radius: 4px;
  background: white;
  border-style: solid;
  border-color: grey;
  border-width: 1px;
  height: 80px;
  width: 120px;
  box-shadow: 1px 0px 2px 0px grey;
  z-index: 100;
}

.standard {
  height: 15px;
  width: 15px;
  background: hotpink;
  border-radius: 50%;
}

.container {
  margin: 20px, 10px, 20px, 10px;
  border-color: grey;
  height: "90vh";
  width: "100%";
}

.filter-container {
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  padding: 8px;
}

.icon {
  height: 26px;
  width: 26px;
  margin: 0 10px 0;
}

.icon-description {
  display: block;
  align-items: center;
  text-align: center;
  width: 100%;
  height: auto;
  font-size: 0.7rem;
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-justify: auto;
  margin: 3px;
}

.area-container {
  padding-top: 10px;
}

.area-item {
  color: grey;
  display: flex;
  justify-content: center;
  height: 40px;
  width: 100%;
  border-style: solid;
  border-color: #f5f5f5;
  border-width: 1px;
  border-collapse: collapse;
  border-bottom: none;
  border-left: none;
  border-right: none;
  font-size: 0.9rem;
  text-align: center;
  line-height: 40px;
  vertical-align: middle;
  background-color: white;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.filter-margin {
  margin-left: -20;
  padding-left: 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.filter-check {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 0.9rem;
  color: grey;
}

.bold-font {
  font-weight: bold;
}

.checkbox {
  -webkit-appearance: none;
  appearance: none;
  display: flex;
  border: 1px lightgrey solid;
  flex-direction: row;
  border-radius: 3px;
  height: 20px;
  width: 20px;
  margin: 6px;
  margin-right: 20px;
  background-color: white;
}

.checkbox:checked {
  background-color: #009ac3;
}

/* Spinner */

.loader {
  color: #009ac3;
  font-size: 10px;
  margin: 10px auto;
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
